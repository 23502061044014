import React from 'react';

const Citations: React.FC = () => {
  return (
    <>
      <h2 id="citation">Citations</h2>

      <div>
        <p>
          Raté <span>/ Fail</span>
        </p>
        <span>Citation des Shadock</span>
      </div>
      <div>
        <p>
          Intello <span>/ Smart</span>
        </p>
        <span>Citation des Shadock</span>
      </div>

      <hr />
    </>
  );
};

export default Citations;
