import 'moment/locale/fr';

import React, { useEffect, useState } from 'react';

import LoadingOrError from '../LoadingOrError';
import { apiUrl } from '../../App';
import games from '../../Images/games.png';
import moment from 'moment';
import twitchLogo from '../../Images/twitchLogo.png';

moment.locale('fr');

interface GroupedSchedule {
  [day: string]: {
    id: number;
    title: string;
    gameImage: string;
    start_time: Date;
    end_time: Date;
    isCanceled: boolean;
    category: {
      name: string;
    };
  }[];
}

const Planning = () => {
  const [planning, setPlanning] = useState<GroupedSchedule | undefined>();
  const [errorMsg, setErrorMsg] = useState<Error | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetch(`${apiUrl}/schedule`);
        const data = await result.json();
        setPlanning(data);
      } catch (e: any) {
        setErrorMsg(e);
      }
    };

    fetchData();
  }, []);

  const planningKeys = planning && Object.keys(planning);

  const returnFlexPosition = (date: number) => {
    switch (true) {
      case date >= 12 && date <= 18:
        return 'center';
        break;
      case date <= 12:
        return 'start';
      case date >= 18:
        return 'end';
      default:
        return 'start';
        break;
    }
  };

  const sameMonth =
    planningKeys &&
    moment(planningKeys[0]).format('MMM') ===
      moment(planningKeys[planningKeys.length - 1]).format('MMM');

  return (
    <section className="planning_wrapper">
      <div className="side">
        <img src={twitchLogo} />
        <div>
          <h3>Planning de stream</h3>
          {planningKeys && (
            <p>
              {sameMonth ? (
                <>
                  Du {moment(planningKeys[0]).format('DD')} au{' '}
                  {moment(planningKeys[planningKeys.length - 1]).format('DD MMMM')}
                </>
              ) : (
                <>
                  Du {moment(planningKeys[0]).format('DD MMMM')} au{' '}
                  {moment(planningKeys[planningKeys.length - 1]).format('DD MMMM')}
                </>
              )}
            </p>
          )}
        </div>
      </div>
      <div className="planning">
        <ul>
          {planning && !errorMsg ? (
            Object.keys(planning).map((day, index) => {
              const flex_position = returnFlexPosition(moment(planning[day][0].start_time).hour());

              return (
                <li key={index}>
                  <div className="day">
                    <p>
                      {moment(day).format('dddd')}
                      <br />
                      <i>{moment(day).format('DD')}</i>
                    </p>
                  </div>
                  <div className={`games ${flex_position}`}>
                    {planning[day].map((event) => {
                      return (
                        <div key={event.id} className={`game ${event.isCanceled && 'canceled'}`}>
                          <div className="red-cross" />
                          <div className="content">
                            <p>{event.title || event.category.name}</p>
                            <span>
                              De {moment(event.start_time).format('HH[h]mm')} à{' '}
                              {moment(event.end_time).format('HH[h]mm')}
                            </span>
                          </div>
                          <img src={event.gameImage || games}></img>
                        </div>
                      );
                    })}
                  </div>
                </li>
              );
            })
          ) : (
            <LoadingOrError errorMsg={errorMsg} />
          )}
        </ul>
      </div>
    </section>
  );
};

export default Planning;
