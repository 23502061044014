import React, { useEffect, useState } from 'react';

import LoadingOrError from '../LoadingOrError';
import { apiUrl } from '../../App';

interface Emote {
  name: string;
  img: string;
  isAnimated: boolean;
}

const Emotes = () => {
  const [errorMsg, setErrorMsg] = useState<Error | undefined>();
  const [emotes, setEmotes] = useState<{ [key: string]: Emote[] }>();

  useEffect(() => {
    const fetchDataEmotes = async () => {
      try {
        const result = await fetch(`${apiUrl}/emotes`);
        const data = await result.json();
        setEmotes(data);
      } catch (e: any) {
        setErrorMsg(e);
      }
    };

    fetchDataEmotes();
  }, []);

  return (
    <section className="more">
      <div className="emotes">
        <h2>Emotes Twitch</h2>
        {emotes ? (
          <>
            <p>Les emotes followers</p>
            {emotes['follower_']?.map((emote: Emote) => {
              return <img key={emote.name} alt={emote.name} src={emote.img} />;
            })}
            <p>Les emotes subs</p>
            {emotes['subscriptions_1000']
              ?.filter((i) => !i.isAnimated)
              .map((emote: Emote) => {
                return <img key={emote.name} alt={emote.name} src={emote.img} />;
              })}
            <p>Les emotes subs animées</p>
            {emotes['subscriptions_1000']
              ?.filter((i) => i.isAnimated)
              .map((emote: Emote) => {
                return <img key={emote.name} alt={emote.name} src={emote.img} />;
              })}
            <p>Les emotes de bits</p>
            {emotes['bitstier_']?.map((emote: Emote) => {
              return <img key={emote.name} alt={emote.name} src={emote.img} />;
            })}
            <p>Les emotes tiers 2 & 3</p>
            {emotes['subscriptions_2000']?.map((emote: Emote) => {
              return <img key={emote.name} alt={emote.name} src={emote.img} />;
            })}
            {emotes['subscriptions_3000']?.map((emote: Emote) => {
              return <img key={emote.name} alt={emote.name} src={emote.img} />;
            })}
          </>
        ) : (
          <LoadingOrError errorMsg={errorMsg} />
        )}
      </div>
    </section>
  );
};

export default Emotes;
