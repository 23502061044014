import React from 'react';

const Divers: React.FC = () => {
  return (
    <>
      <h2 id="divers">Divers</h2>

      <div>
        <p>Lunettes</p>
        <span>Pour me rappeler de mettre mes lunettes</span>
      </div>
      <div>
        <p>
          Decor <span>/ Design</span>
        </p>
        <span>
          Explique comment utiliser la récompense &quot;Décorateur d&apos;interieur&quot;{' '}
        </span>
      </div>
      <div>
        <p>Uptime</p>
        <span>Donne la durée actuelle du stream</span>
      </div>
      <div>
        <p>RTFM</p>
        <span>Read The Fucking Manual</span>
      </div>

      <div className="modo_only">
        <p>SO</p>
        <span>Pour faire un shoutout sur une chaine</span>
      </div>

      <hr />
    </>
  );
};

export default Divers;
