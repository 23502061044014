import Cake from '../Images/overlay/Cake.png';
import Cat1 from '../Images/overlay/Cat1.png';
import Cat2 from '../Images/overlay/Cat2.png';
import Coffee from '../Images/overlay/Coffee.png';
import Emotes from '../Components/Home/Emotes';
import Flower from '../Images/overlay/Flower.png';
import Games from '../Components/Home/Games';
import Planning from '../Components/Home/Planning';
import insta from '../Images/insta.png';
import mail from '../Images/mail.png';
import profil from '../Images/profil.png';
import twitter from '../Images/twitter.png';
import youtube from '../Images/youtube.png';

const HomePage = () => {
  return (
    <div className="homepage">
      <div className="header" />
      <div className="wrapper">
        <section className="rezo_wrapper">
          <div className="profil">
            <img src={profil} />
          </div>
          <div className="rezo">
            <h1>Aurélie (Aka Ptits Clous)</h1>
            <p>
              Youtubeuse DIY qui aime bricoler et jouer en papotant. Je stream ANCH le matin,
              parfois le soir c&apos;est du cooooode et le WE on fait des bêtises :) <br />
              Je vous souhaite la bienvenue sur ma chaîne ! Bisous (et abonnez-vous ❤️)
            </p>

            <div className="rezo_links">
              <a href="https://twitter.com/ptits_clous">
                <img src={twitter} />
              </a>
              <a href="https://instagram.com/ptits_clous">
                <img src={insta} />
              </a>
              <a href="https://www.youtube.com/c/LesPtitsClous/videos">
                <img src={youtube} />
              </a>
            </div>
            <a className="link" href="https://twitch.tv/ptits_clous">
              Viens me voir sur Twitch {'>'}
            </a>
          </div>
          <a className="contact" href="mailto:monptitclou@gmail.com">
            <img src={mail} />
            <p>Contact</p>
          </a>
        </section>

        <Planning />

        <div className="separator">
          <img src={Flower} className="flower" />
          <img src={Cat2} className="cat2" />
          <hr />
        </div>

        <Games />

        <div className="separator">
          <img src={Coffee} className="coffee" />
          <img src={Cake} className="cake" />
          <hr />
        </div>

        <Emotes />

        <div className="separator">
          <img src={Cat1} className="cat1" />
          <hr />
        </div>
        <a className="link" href="/commandes">
          Accéder aux commandes du bot {'>'}
        </a>
      </div>
    </div>
  );
};

export default HomePage;
