import React from 'react';
import left1 from '../../Images/pixel-art/left1.png';
import left2 from '../../Images/pixel-art/left2.png';
import left3 from '../../Images/pixel-art/left3.png';
import right1 from '../../Images/pixel-art/right1.png';
import right2 from '../../Images/pixel-art/right2.png';
import right3 from '../../Images/pixel-art/right3.png';

const Recompenses: React.FC = () => {
  return (
    <>
      <h2 id="recompenses">Récompense : Décorateur d&apos;interieur</h2>

      <div className="text">
        <p>Composez votre décor en écrivant :</p>
        <span>• Num Item Gauche - Num Item Droite</span>
        <br /> <br />
        <span>
          Exemple : <b>3-1</b> donnera gâteau et plante
        </span>
        <table className="table">
          <thead>
            <tr>
              <th>numéro</th>
              <th>Item Gauche</th>
              <th>Item Droite</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <img src={left1} />
              </td>
              <td>
                <img src={right1} />
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <img src={left2} />
              </td>
              <td>
                <img src={right2} />
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <img src={left3} />
              </td>
              <td>
                <img src={right3} />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <span>Si la commande n&apos;est pas reconnue le résultat sera aléatoire !</span>
      </div>

      <h2 id="recompenses">Autres récompenses</h2>

      <div>
        <p>Tombola</p>
        <span>
          Vous donne la liste des inscrits pour la prochaine Tombola. <br />
          Pour tenter votre chance : sélectionnez la récompense &quot;Tombola&quot;.
        </span>
      </div>
      <hr />
    </>
  );
};

export default Recompenses;
