import './App.css';

import { Route, Routes } from 'react-router-dom';

import BotAClous from './Pages/BotAClous';
import HomePage from './Pages/Homepage';
import Layout from './Components/Layout';

// export const apiUrl = 'http://localhost:3001/api';
export const apiUrl = 'https://ptits-clous.fr/api';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/commandes" element={<BotAClous />} />

          <Route
            path="*"
            element={
              <main style={{ padding: '1rem' }}>
                <p>Nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>{' '}
    </div>
  );
}

export default App;
