import React from 'react';

const Reseaux: React.FC = () => {
  return (
    <>
      <h2 id="rs">Réseaux Sociaux</h2>
      <div>
        <p>
          Réseaux <span>/ RS</span>
        </p>
      </div>
      <div>
        <p>
          Discord <span>/ Contact</span>
        </p>
      </div>
      <div>
        <p>
          Youtube <span>/ Yt</span>
        </p>
      </div>
      <div>
        <p>
          Twitter <span>/ Tw</span>
        </p>
      </div>
      <div>
        <p>
          Instagram <span>/ Insta</span>
        </p>
      </div>
      <div>
        <p>
          Tiktok <span>/ Toktok</span>
        </p>
      </div>
      <div>
        <p>
          OnlyFan <span>/ Of</span>
        </p>
      </div>

      <hr />
    </>
  );
};

export default Reseaux;
