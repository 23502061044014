import React from 'react';

const Jeux: React.FC = () => {
  return (
    <>
      <h2 id="jeux">Animal Crossing & autres jeux</h2>

      <div>
        <p>
          CodeSwitch <span>/ codeami / switch</span>
        </p>
        <span>Mon code ami Switch</span>
      </div>
      <div>
        <p>
          DodoCode <span>/ Dodo</span>
        </p>
        <span>Vous donne les instructions pour venir sur mon île ACNH</span>
      </div>
      <div>
        <p>
          CodeOnirique <span>/ Orinique</span>
        </p>
        <span>Vous donne les codes orinique de mes anciennes îles ACNH</span>
      </div>
      <div>
        <p>
          Gauche <span>/ Left</span>
        </p>
        <span>Quand je me trompe de gauche</span>
      </div>
      <div>
        <p>
          Droite <span>/ Right</span>
        </p>
        <span>Quand je me trompe de droite</span>
      </div>

      <hr />
    </>
  );
};

export default Jeux;
