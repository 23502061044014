import { Outlet } from 'react-router-dom';
import React from 'react';

const Layout: React.FC = () => {

  return (
    <div className="main-wrapper">
     <Outlet />
    </div>
  );
};

export default Layout;
