import React, { useEffect, useState } from 'react';

import LoadingOrError from '../LoadingOrError';
import { apiUrl } from '../../App';

interface Game {
  id: number;
  image_link: string;
  name: string;
}

const Games = () => {
  const [latestGames, setLatestGames] = useState<Game[]>();
  const [errorMsg, setErrorMsg] = useState<Error | undefined>();

  useEffect(() => {
    const fetchDataGames = async () => {
      try {
        const result = await fetch(`${apiUrl}/played-games`);
        const data = await result.json();
        setLatestGames(data);
      } catch (e: any) {
        setErrorMsg(e);
      }
    };

    fetchDataGames();
  }, []);
  return (
    <section className="played_games">
      <h2>Les jeux du moment</h2>
      <p>Sur les dernières semaines</p>

      <div className="games_list">
        {latestGames ? (
          latestGames.map((game) => {
            return <img alt={game.name} key={game.id} src={game.image_link} />;
          })
        ) : (
          <LoadingOrError errorMsg={errorMsg} />
        )}
      </div>
    </section>
  );
};

export default Games;
