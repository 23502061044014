import React from 'react';
import errorImg from '../Images/errorImg.gif';

const LoadingOrError: React.FC<{ errorMsg?: Error }> = ({ errorMsg }) => {
  return errorMsg ? (
    <div className="error_component">
      <img src={errorImg} alt="image d'erreur 404" />
    </div>
  ) : (
    <div className="spinner"></div>
  );
};

export default LoadingOrError;
