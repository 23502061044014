import React from 'react';

const Bruitages: React.FC = () => {
  return (
    <>
      <h2 id="bruits">Bruitages</h2>

      <div className="vip_only">
        <p>
          Coin <span>/ canard</span>
        </p>
        <span>Bruit de canard</span>
      </div>
      <div className="vip_only">
        <p>
          JeCode <span>/ JeCodeAvecLeCul</span>
        </p>
        <span>Chanson : &quot;Je code avec le Q !&quot;</span>
      </div>
      <div className="vip_only">
        <p>TrustMe</p>
        <span>Chanson : &quot;Trust me, i&apos;m an engineer !&quot;</span>
      </div>

      <hr />
    </>
  );
};

export default Bruitages;
