import Bruitages from '../Components/Bot/bruitage';
import Citations from '../Components/Bot/citations';
import Divers from '../Components/Bot/divers';
import Jeux from '../Components/Bot/jeux';
import Recompenses from '../Components/Bot/recompenses';
import Reseaux from '../Components/Bot/reseaux';
const Planning = () => {
  return (
    <div className="bot_a_clous">
      <div className="header" />
      <div className="wrapper">
        <div className="sidebar">
          <ul>
            <li>
              <a href="#jeux">Commandes jeux</a>
            </li>
            <li>
              <a href="#divers">Divers</a>
            </li>
            <li>
              <a href="#recompenses">Récompenses</a>
            </li>
            <li>
              <a href="#citation">Citations</a>
            </li>
            <li>
              <a href="#rs">Réseaux Sociaux</a>
            </li>
            <li>
              <a href="#bruits">Bruitages</a>
            </li>
          </ul>
        </div>
        <div className="content">
          <a className="link" href="/">
            {'<'} Revenir à l&apos;accueil
          </a>
          <h1>Commandes du Bot @ptits_clous</h1>

          <Jeux />
          <Divers />
          <Recompenses />
          <Citations />
          <Reseaux />
          <Bruitages />
        </div>
      </div>
    </div>
  );
};

export default Planning;
